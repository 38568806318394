<template>
    <div style="font-size:28px; height: 100%; display: flex; align-items: center; justify-content: center;font-weight: bold;">
        ACCESS ALLOWED FOR AUTHORIZED USER ONLY
    </div>
</template>

<script>
import tab from '@/mixins/tab'
export default {
    name: 'blackbox',
    mixins: [tab],
}
</script>
